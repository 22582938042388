<template>
  <div class="class">
    <div class="top">
       <el-button class="el-but2" type="primary" @click="go()" >返回</el-button>
      <span style="padding-left:10px">{{this.$route.query.className}}</span>
    </div>
    <el-row style="border-top: 10px solid #f5f5f5;border-bottom: 10px solid #f5f5f5;padding: 10px 0;">
      <el-col style="display: flex;align-items: center;justify-content: flex-start;padding-left: 10px;">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item label="学员名称：">
            <el-input type="text" v-model="stuName" placeholder="请输入学员名称"></el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-button type="primary" @click="search()">搜索</el-button>
          <el-button type="warning" @click="rest()">重置</el-button>
          <el-button type="primary" @click="downStudentHandbook()">导出学员手册</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row class="bg">
    <el-table
      :data="curriculumData">
      <el-table-column
        label="学员姓名">
        <template slot-scope="scope">
          <span class="spani">{{ scope.row.stuName}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="出生日期">
        <template slot-scope="scope">
          <span>{{scope.row.stuBirth}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="当前职务">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.duty}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="联系电话">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.tel}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="单位">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.depName}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="性别">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.sex}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="干部层级">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.stuLeadDuty}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="300px">
        <template slot-scope="scope">
          <el-button size="mini" @click="Delete(scope.row)" type="danger">删除</el-button>
          <el-button size="mini" type="primary" @click="Export(scope.row)">导出考勤</el-button>
          <el-button size="mini" @click="ChangeClass(scope.row)" type="primary">转班</el-button>
        </template>
      </el-table-column>
      </el-table>
    </el-row>
    <!-- 转班 -->
    <el-dialog title="换班" :visible.sync="Classshow">
      <el-form>
        <el-form-item label="班级" label-width="150px">
          <el-select v-model="newClassId" placeholder="请选择班级" style="width:100%">
           <el-option v-for="(item,index) in classData"
                    :key="index"
                    :label="item.className"
                    :value="item.classId"/>
          </el-select>
        </el-form-item>
         <el-button @click="ClassCancel">取消</el-button>
         <el-button @click="newClassBt()" type="primary" :disabled="!newClassId">确定</el-button>
      </el-form>
    </el-dialog>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-size="pageSize"
      layout="total,sizes,  prev, pager, next, jumper"
      :total="total"
      class="pag"
    >
    </el-pagination>
  </div>
</template>

<script>
  import { classList, classdownLoad, getAllClassByMoHu, downStudentHandbook } from '@/api/user.js'
  import { DeleteStudent, newClassTwo } from '@/api/admin.js'
  import { mapMutations } from 'vuex'
  // import { getCookie } from '@/utils/base'
  export default {
    name: 'Classinformation',
    data () { // 数据模型
      return {
        // ChangeClassbt: getCookie('admin'),
        Classshow: false,
        newClassId: '',
        stuName: '',
        classData: [], // 存储班级信息
        stuId: '', // 学员id
        classId: '',
        curriculumData: [],
        pageNum: 1,
        pageSize: 10,
        total: 0,
        isDisable: false
      }
    },
    mounted() {
      this.ClassLise()
      this.findClass()
    },
    methods: {
      ...mapMutations({
        saveUser: 'SAVE_USER'
      }),
      // 获取班级列表
      findClass() {
        getAllClassByMoHu().then(response => {
          this.classData = response.data.data.rows
        })
      },
      // 转班级
      ChangeClass(row) {
        this.Classshow = true
        this.stuId = row.id
        this.classId = this.$route.query.classId
      },
      // 转班级提交
      newClassBt() {
        newClassTwo(this.stuId, this.classId, this.newClassId).then(res => {
              if (res.data.code === '0') {
                this.$notify({
                  title: '转班成功',
                  type: 'success'
                })
                this.newClassId = ''
                this.Classshow = false
                this.ClassLise()
              } else {
                this.$notify.error({
                  title: res.data.msg
                })
              }
        })
      },
      // 转班级取消
      ClassCancel() {
        this.newClassId = ''
        this.Classshow = false
      },
      // 返回上一个页面
      go() {
         this.$router.go(-1)
      },
      // 搜索
      search() {
        this.pageNum = 1
        this.ClassLise()
      },
      // 搜索
      rest() {
        this.pageNum = 1
        this.stuName = ''
      },
      // 导出
      Export(row) {
        const query = {
            classId: this.$route.query.classId,
            stuId: row.id
          }
        classdownLoad(query).then(resp => {
          const link = document.createElement('a')
          const blob = new Blob([resp.data], { type: 'application/vnd.ms-excel' })
           link.style.display = 'none'
           // 设置连接
           link.href = URL.createObjectURL(blob)
           link.download = row.stuName + '考勤表.xlsx'
           document.body.appendChild(link)
           // 模拟点击事件
           link.click()
        }).catch((err) => {
          console.log(err)
        })
      },
      // 导出
      downStudentHandbook() {
           const classId = this.$route.query.classId
        downStudentHandbook(classId).then(resp => {
          const link = document.createElement('a')
          const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8' })
           link.style.display = 'none'
           // 设置连接
           link.href = URL.createObjectURL(blob)
           link.download = '学员手册.docx'
           document.body.appendChild(link)
           // 模拟点击事件
           link.click()
        })
      },
      handleSizeChange(e) {
        this.pageSize = e
        this.ClassLise()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.ClassLise()
      },
      // 列表信息
       ClassLise() {
          const query = {
            classId: this.$route.query.classId,
            stuName: this.stuName,
            pageNum: this.pageNum,
            pageSize: this.pageSize
          }
          classList(query).then(resp => {
            if (resp.data.code === '0') {
                this.total = resp.data.data.total // 查询总条数
                this.curriculumData = resp.data.data.rows // 接收返回的数据
             }
          })
        },
       // Delete
      Delete(row) {
        console.log(row)
        this.$confirm('是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((e) => {
          if (e === 'confirm') {
            DeleteStudent(row.id, this.$route.query.classId).then(res => {
              if (res.data.code === '200') {
                this.$notify({
                  title: '删除成功',
                  type: 'success'
                })
                const index = this.curriculumData.indexOf(row)
                this.curriculumData.splice(index, 1)
                this.total--
              } else {
                this.$notify.error({
                  title: res.data.msg
                })
              }
            })
          }
        }).catch(() => {
        })
      }
    }
  }
</script>

<style>
  [v-cloak] {
    display:none !important;
  }
  .bg{margin-top: 10px; padding: 20px; background-color: white;}
  .class{
    width: 100%;
    background-color: white;
    position: relative;
    margin-top: -10px;
  }
  .top{
    width: 100%;
    height: 60px;
    background-color: #e9e9e9;
    display: flex;
    align-items: center;
  }
  .num{
    color: #2c2fd6;
  }
  .apply{
    position: absolute;
    left: 30px;
    margin: 0;
  }
  .inp{
    width: 250px;
    position: absolute;
    left: 180px;
    border: none;
  }
  .el-but{
    position: absolute;
    left: 440px;
  }
    .el-but2{ margin-left: 20px;
  }
  .left-tag p{
    margin-right: 30px;
  }
  .pag{position: absolute;right:10px}
  .big div{flex: 1}
  .download{margin:auto; width: 150px; line-height: 31px; display: block; border-radius:4px;
    color: #FFF; background-color: #409EFF; border-color: #409EFF;text-align:center;
    font-size:12px; text-decoration:none; cursor:pointer;}
  .download:hover{color: #FFF; background-color: #70b3f9; border-color: #70b3f9; text-decoration:none; }
    .map {
    width: 300px;
    height: 250px;
  }
  .el-form-item {
    margin-bottom: 0 !important;
  }
</style>
